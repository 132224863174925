import React, { useState } from "react"
import { Form, Field } from "react-final-form"
import { FORM_ERROR } from "final-form"

import { FadeInTop } from "../components/animations"

import loadingSpinnerLight from "../assets/images/loading-spinner-light.svg"
import loadingSpinnerDark from "../assets/images/loading-spinner-dark.svg"
import confettiAnimation from "../assets/images/confetti-animation.gif"

const SubscribeSection = ({ lightVariant }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const submitURL = "/.netlify/functions/subscribe"

  const onSubmit = values => {
    return fetch(submitURL, {
      method: "post",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => {
        if (!res.ok) {
          throw Error(res.body)
        }
        setIsSubmitted(true)
      })
      .catch(err => {
        console.log(err)
        setIsSubmitted(false)
        return {
          [FORM_ERROR]: "Something went wrong please try again...",
        }
      })
  }

  return (
    <section
      id="subscribe"
      className={lightVariant ? "bg-white" : ""}
      id="subscribe"
    >
      <div className="c-container-full pt-7 pb-11 lg:pt-24 lg:pb-24">
        <FadeInTop>
          <h3
            className={`${
              lightVariant ? "text-black " : ""
            }  text-4xl lg:text-5xl  xl:text-7xl leading-snug lg:leading-normal xl:leading-normal font-headings2 max-w-[304px] lg:max-w-[420px] xl:max-w-[656px] mb-12 lg:mb-28`}
          >
            Subscribe{" "}
            <span className="text-[#666]">and never miss a thing</span>
          </h3>
        </FadeInTop>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting, submitError, form }) =>
            isSubmitted ? (
              <div>
                <img
                  className="block mx-auto pl-5 lg:w-[200px]"
                  src={confettiAnimation}
                  alt=""
                  width="140px"
                />
                <FadeInTop delay={0.3}>
                  <h4
                    className={`${
                      lightVariant ? "text-black " : ""
                    }c-container-full text-center mt-8 mb-24 font-headings text-2xl lg:text-4xl leading-snug lg:leading-snug`}
                  >
                    Yay!
                    <br /> Check your mailbox to confirm subscription.
                  </h4>
                </FadeInTop>
              </div>
            ) : (
              <>
                <form
                  className="font-headings2 lg:flex lg:text-xl"
                  onSubmit={handleSubmit}
                >
                  <div className="lg:w-5/12 c-form-field-wrapper">
                    <Field name="firstname">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            id="subscribe-firstname"
                            className={`${
                              lightVariant
                                ? "text-black focus:border-black "
                                : "text-white focus:border-white"
                            }`}
                            type="text"
                            required
                          />
                          <label htmlFor="subscribe-firstname">
                            First Name <em>*</em>
                          </label>
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="mt-10 lg:mt-0 lg:w-5/12 lg:ml-7 c-form-field-wrapper">
                    <Field name="email">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            id="subscribe-email"
                            className={`${
                              lightVariant
                                ? "text-black focus:border-black "
                                : "text-white focus:border-white"
                            }`}
                            type="email"
                            required
                          />
                          <label htmlFor="contact-email">
                            Email <em>*</em>
                          </label>
                        </>
                      )}
                    </Field>
                  </div>
                  <button
                    className={`${
                      lightVariant ? "text-black " : ""
                    }font-headings2 mt-10 lg:mt-0 lg:w-1/12 anim-elastic-line`}
                    disabled={submitting}
                  >
                    {submitting ? (
                      <img
                        className="w-7 top-1/2 left-1/2"
                        src={
                          lightVariant
                            ? loadingSpinnerDark
                            : loadingSpinnerLight
                        }
                        alt=""
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
                {/* show error only on form submit error and make error disappear when form is edited after failed submissix`on */}
                {submitError && !form.getState().dirtySinceLastSubmit && (
                  <p className=" text-red-400 mt-4">
                    Something went wrong. Please try again...
                  </p>
                )}
              </>
            )
          }
        </Form>
      </div>
    </section>
  )
}

export default SubscribeSection
